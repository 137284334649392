<template>
    <div class="legislat xi">
        <nh-com></nh-com>
        <div class="banner">
            <div class="banner-wrap clearfix">
                <div class="banner-text">
                    <h3>法律法规</h3>
                    <p>
                        当前位置：
                        <router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/legislation/Legislation">法律法规</router-link>
                    </p>
                </div>
                <!--<div class="banner-search">
                    <input type="text" placeholder="输入关键词，按回车键(Enter)进行搜索">
                    <button class="search-btn el-icon-search"></button>
                </div>-->
            </div>
        </div>
        <div class="content">
            <div class="ny-content" v-if="legisDetail">
                <div class="legis-tit">
                    <h3 data-size="26">{{legisDetail.title}}</h3>
                    <p class="" data-size="14">
                        <span  v-if="legisDetail.source">来源：{{legisDetail.source}}</span>
                        <span v-if="legisDetail.postDate">时间：{{legisDetail.postDate.replace(/T/g, ' ').replace(/.[\d]{3}Z/, ' ')}}</span>
                    </p>
                </div>
                <div class="legis-content">
                    <div  data-size="18" class="piece-content c-gap-bottom" v-html="legisDetail.content">
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import ftCom from '../../components/footer'
    import nhCom from "../../components/navheader";
    import rtBar from "../../components/right";
    import { Legislation } from "../../components/Legislation/Legislation"
    export default {
        name: "LegislaDetail",
        components: {
            ftCom,
            nhCom,
            rtBar
        },
        data() {
            var legislation = new Legislation(this.TokenClient, this.Services.Statics)
            return {
                id: this.$route.query.id, //行业遵从id
                legisDetail: {}, //初始化详情
                legislationDomain: legislation
            }
        },
        methods: {
            //详情获取
            legislationDetail: function () {
                var _this = this;
                let params = `${this.id}`;
                _this.legislationDomain.Detail(params, function (data) {
                    _this.legisDetail = data.data
                },
                function (error) {
                    console.log("行业遵从详情获取失败:", error)
                })
            },
        },
        mounted() {
            this.legislationDetail();
        }
    }
</script>

<style scoped>
    @import "../../assets/css/footer.css";

    .banner {
        width: 100%;
        height: 160px;
        background: url("../../assets/img/news_banner.jpg") center no-repeat;
    }

    .banner-wrap {
        position: relative;
        width: 1200px;
        height: 100%;
        margin: 0 auto;
    }

    .banner-text {
        float: left;
    }

        .banner-text h3 {
            padding-top: 35px;
            font-size: 26px;
            font-weight: normal;
            color: #ffffff;
        }

        .banner-text p {
            font-size: 14px;
            color: #ffffff;
            margin-top: 20px;
        }

            .banner-text p a {
                color: #ffffff;
                font-size: 14px;
                margin: 0 5px;
            }

    .banner-search {
        position: absolute;
        top: calc(50% - 30px);
        right: 0;
        padding: 0 30px;
        width: 850px;
        height: 60px;
        border-radius: 60px;
        box-sizing: border-box;
        background: rgba(255, 255, 255, 0.7);
    }

        .banner-search input {
            float: left;
            height: 60px;
            line-height: 60px;
            background: none;
            width: 700px;
            font-size: 16px;
        }

        .banner-search .search-btn {
            float: right;
            font-size: 28px;
            color: #1fb7a6;
            background: none;
            line-height: 60px;
        }

    /**/
    .content {
        background: #f7f7f7;
        padding: 40px 0;
    }

    .ny-content {
        width: 1200px;
        margin: 0 auto;
        padding: 20px 35px;
        background: #FFFFFF;
        box-sizing: border-box;
    }

    .legis-tit {
        margin-bottom: 30px;
    }

        .legis-tit h3 {
            font-size: 26px;
            color: #333333;
            font-weight: 700;
            line-height: 2;
            text-align: center;
        }

        .legis-tit p {
            text-align: center;
            font-size: 14px;
            color: #666666;
        }
</style>
<style>
    .legislat .navheader nav .navbar ul li.active>a{
        color: #333333 !important;
    }
    .legislat .navheader nav .navbar ul li.active>a::before{
        width: 0 !important;
    }
    .legislat .bar-list .yysc{
        display: none !important;
    }
    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li {
        border-radius: 50%;
    }

    .el-pagination {
        font-weight: 500;
    }

    .piece-content h1 span {
        font-size: 20px ;
        color: #333333 !important;
        font-weight: 700 !important;
    }

    .piece-content h2 span {
        font-size: 16px ;
        color: #333333 !important;
        line-height: 3;
    }

    .piece-content span {
        font-size: 14px ;
        color: #555555 !important;
        line-height: 2;
    }
</style>
